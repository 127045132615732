import React, { useContext, useEffect, useState } from 'react'
import Loader from 'react-spinners/PulseLoader'
import { AuthContext } from '../context/AuthContext'
import { listSupplierInvoices } from './erp-backend'
import InvoicesList from './InvoicesList'

type Props = {
  onInvoiceSelected: () => void
  multiSelectEnabled: boolean
  onSelectionChange: (selectedInvoices: any[]) => void
  renderInvoices: (props: any) => JSX.Element
}

function InvoicesListContainer({ onInvoiceSelected, multiSelectEnabled, onSelectionChange, renderInvoices }: Props) {
  const { authState, signOut } = useContext(AuthContext)
  const [invoices, setInvoices] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [, setError] = useState(null)

  useEffect(() => {
    if (authState.type === 'SignedOut') {
      throw new Error('User is not authenticated')
    }
    const fetchData = async () => {
      console.log('fetching data')
      try {
        setLoading(true)
        setError(null)

        const invoices = await listSupplierInvoices(authState.token, signOut)

        console.log(invoices)
        // @ts-ignore
        setInvoices(invoices)
      } catch (e) {
        console.log(e)
        setError(e.message)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (renderInvoices) {
    return renderInvoices({ invoices, isLoading, onInvoiceSelected, multiSelectEnabled, onSelectionChange })
  }

  return (
    <>
      <h1>
        Invoices <Loader loading={isLoading} size={10} />
      </h1>
      <InvoicesList
        // @ts-ignore
        loading={isLoading}
        invoices={invoices}
        onInvoiceSelected={onInvoiceSelected}
        multiSelectEnabled={multiSelectEnabled}
        onSelectionChange={onSelectionChange}
      />
    </>
  )
}

export default InvoicesListContainer
