import * as option from 'fp-ts/Option'
import { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { AuthContext } from './context/AuthContext'
import styles from './Header.module.css'
import { ReactComponent as LogoInternal } from './logo.svg'
import requiredData, { RequiredData } from './utils/RequiredData'
import { getUserInfo, UserInfo } from './utils/UserInfo'

function Header() {
  return (
    <header className={styles.header}>
      <Logo />
      <UserInfoAndActions />
    </header>
  )
}

function Logo() {
  return (
    <Link to="/">
      <LogoInternal className={styles.logo} />
    </Link>
  )
}

function UserInfoAndActions() {
  const history = useHistory()
  const { authState, signOut } = useContext(AuthContext)
  const [userInfo, setUserInfo] = useState<RequiredData<UserInfo>>(requiredData.loading())

  useEffect(() => {
    if (authState.type === 'SignedIn') {
      getUserInfo(authState.token).then((x) => setUserInfo(requiredData.success(x)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const companyName = requiredData.fold<UserInfo, string>({
    onLoading: () => '...',
    onFailure: () => '',
    onSuccess: ({ companyName }) => option.getOrElse(() => '')(companyName),
  })(userInfo)

  const redirectToLoginPage = () => history.push('/login')

  return authState.type === 'SignedIn' ? (
    <div className={styles.companyName}>
      {companyName}
      <button onClick={signOut}>Sign Out</button>
    </div>
  ) : (
    <div className={styles.companyName}>
      <button onClick={redirectToLoginPage}>Sign In</button>
    </div>
  )
}

export default Header
