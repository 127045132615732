import { isRight } from 'fp-ts/Either'
import { exact, literal, string, type, TypeOf, undefined as undefinedC, union } from 'io-ts'
import React, { createContext, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { postJson } from '../utils/backendRequest'
import { identifyLogrocketUser } from '../utils/LogRocket'
import LogRocket from 'logrocket'

const signedInC = exact(
  type({
    type: literal('SignedIn'),
    token: string,
  }),
)

const signedOutC = exact(
  type({
    type: literal('SignedOut'),
    lastRequestedPath: union([string, undefinedC]),
  }),
)

const authStateC = union([signedInC, signedOutC])

/**
 * Loading state and error handling should be handled in the form, not in the auth state
 */
type AuthState = TypeOf<typeof authStateC>

type AuthStore = {
  authState: AuthState
  signIn: (email: string, password: string) => Promise<void>
  signOut: () => void
}

const initialValue: AuthStore = {
  authState: { type: 'SignedOut', lastRequestedPath: undefined },
  signIn: () => Promise.reject(),
  signOut: () => {},
}

export const AuthContext = createContext<AuthStore>(initialValue)

export const AuthContextProvider: React.FunctionComponent<{
  children: JSX.Element
}> = ({ children }) => {
  const history = useHistory()
  // If we use useEffect here, we will end up with an "Unknown" auth state,
  // because it will be defined after first render.
  const initialAuthState = useMemo(() => {
    let persistedAuthState: AuthState
    const signedOut: AuthState = { type: 'SignedOut', lastRequestedPath: history.location.pathname }
    try {
      const persistedJson = JSON.parse(localStorage.getItem('authState') || '')
      const decoded = authStateC.decode(persistedJson)
      if (isRight(decoded)) {
        persistedAuthState = decoded.right
      } else {
        persistedAuthState = signedOut
      }
    } catch (_) {
      persistedAuthState = signedOut
    }

    if (persistedAuthState.type === 'SignedIn') {
      identifyLogrocketUser(persistedAuthState.token)
    }
    return persistedAuthState
  }, [history])
  const [authState, setAuthState] = useState<AuthState>(initialAuthState)

  const signOut = () => {
    const newState: AuthState = {
      type: 'SignedOut',
      lastRequestedPath: history.location.pathname,
    }
    setAuthState(newState)
    localStorage.setItem('authState', JSON.stringify(newState))
  }

  // The signIn function can throw an error,
  // it doesn't handle rejections by design,
  // because there is no place in AuthContext
  // to store error messages and loading state
  const signIn = async (email: string, password: string) => {
    if (authState.type === 'SignedOut') {
      const { token } = await postJson<{ token: string }>('/api/user/login', {
        email,
        password,
      })

      const newState: AuthState = {
        type: 'SignedIn',
        token,
      }

      setAuthState(newState)
      localStorage.setItem('authState', JSON.stringify(newState))
      LogRocket.startNewSession()
      identifyLogrocketUser(newState.token)

      if (authState.type === 'SignedOut' && authState.lastRequestedPath) {
        const { lastRequestedPath } = authState
        const newPath = ['/login', '/login/'].includes(lastRequestedPath) ? '/' : lastRequestedPath
        history.push(newPath)
      }
      return
    }
  }

  const store: AuthStore = {
    authState,
    signIn,
    signOut,
  }
  return <AuthContext.Provider value={store}>{children}</AuthContext.Provider>
}
