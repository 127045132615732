import { isLeft } from 'fp-ts/Either'
import { exact, string, type, TypeOf } from 'io-ts'
import { option } from 'io-ts-types'
import { getJson } from './backendRequest'

const userInfoC = exact(
  type({
    name: string,
    email: string,
    companyName: option(string),
  }),
)

export type UserInfo = TypeOf<typeof userInfoC>

export const getUserInfo = async (token: string): Promise<UserInfo> => {
  const response = await getJson<{ user: UserInfo }>('/api/user-info', {}, token)
  const user = userInfoC.decode(response.user)
  if (isLeft(user)) {
    throw new Error('Got an unexpected response from server. Please refresh the page and try again')
  }
  return user.right
}
