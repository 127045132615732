import fetch from 'cross-fetch'

/**
 * Post JSON
 * Parse response as JSON (only JSON! No typesafety provided here!)
 * Optionally use Authorization token
 */
export const postJson = async <A = unknown>(
  path: string,
  body: unknown,
  token?: string,
  onAuthFailure?: () => void,
): Promise<A> => {
  const options: RequestInit = {
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }

  if (token) {
    // @ts-ignore
    options.headers['Authorization'] = `Bearer ${token}`
  }

  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${path}`, options)

  if (!response.ok) {
    const body = await response.text()
    if (isInvalidTokenError(body) && onAuthFailure) {
      onAuthFailure()
    }
    throw new Error(body)
  }

  if (response.status === 204) {
    // @ts-ignore
    return
  }

  return await response.json()
}

/**
 * Put JSON
 * Parse response as JSON (only JSON! No typesafety provided here!)
 * Optionally use Authorization token
 */
export const putJson = async <A = unknown>(
  path: string,
  body: unknown,
  token?: string,
  onAuthFailure?: () => void,
): Promise<A> => {
  const options: RequestInit = {
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
  }

  if (token) {
    // @ts-ignore
    options.headers['Authorization'] = `Bearer ${token}`
  }

  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${path}`, options)

  if (!response.ok) {
    const body = await response.text()
    if (isInvalidTokenError(body) && onAuthFailure) {
      onAuthFailure()
    }
    throw new Error(body)
  }

  if (response.status === 204) {
    // @ts-ignore
    return
  }

  return await response.json()
}

/**
 * Send query params from object
 * Parse response as JSON (only JSON! No typesafety provided here!)
 * Optionally use Authorization token
 */
export const getJson = async <A = unknown>(
  path: string,
  queryParams: Record<string, string | number | boolean>,
  token?: string,
  onAuthFailure?: () => void,
): Promise<A> => {
  const options: RequestInit = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  if (token) {
    // @ts-ignore
    options.headers['Authorization'] = `Bearer ${token}`
  }

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}${path}`, // TODO: encode query params
    options,
  )

  if (!response.ok) {
    const body = await response.text()
    if (isInvalidTokenError(body) && onAuthFailure) {
      onAuthFailure()
    }
    throw new Error(body)
  }

  if (response.status === 204) {
    // @ts-ignore
    return
  }

  return await response.json()
}

export const getBlob = async (
  path: string,
  queryParams: Record<string, string | number | boolean>,
  token?: string,
  onAuthFailure?: () => void,
): Promise<Blob> => {
  const options: RequestInit = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  if (token) {
    // @ts-ignore
    options.headers['Authorization'] = `Bearer ${token}`
  }

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}${path}`, // TODO: encode query params
    options,
  )

  if (!response.ok) {
    const body = await response.text()
    if (isInvalidTokenError(body) && onAuthFailure) {
      onAuthFailure()
    }
    throw new Error(body)
  }

  return await response.blob()
}

const isInvalidTokenError = (bodyText: string): boolean => {
  try {
    const parsed = JSON.parse(bodyText)
    return typeof parsed === 'object' && parsed.error === 'Invalid authorization token'
  } catch (err) {
    return false
  }
}
