/**
 * This module is designed to be imported with prefix:
 * import requiredData, { RequiredData } from ...
 */

import { assertNever } from './assertNever'

/**
 * This type represents state of remotely loaded data.
 * Most useful for automatically-loaded data.
 */
export type RequiredData<A> =
  | { type: 'Loading' }
  | { type: 'Failure'; errorMessage: string }
  | { type: 'Success'; value: A }

/**
 * Note: smart-constructors are helpful because they produce different objects every time.
 */

/**
 * Smart-constructor
 */
const loading = <A = never>(): RequiredData<A> => ({
  type: 'Loading',
})

/**
 * Smart-constructor
 */
const failure = <A = never>(errorMessage: string): RequiredData<A> => ({
  type: 'Failure',
  errorMessage,
})

/**
 * Smart-constructor
 */
const success = <A>(value: A): RequiredData<A> => ({
  type: 'Success',
  value,
})

/**
 * "Extracts value" from RequiredData
 * by converting each possible variant to a value of some speific type
 */
const fold =
  <A, B>(handlers: { onLoading: () => B; onFailure: (errorMessage: string) => B; onSuccess: (value: A) => B }) =>
  (requiredData: RequiredData<A>): B => {
    switch (requiredData.type) {
      case 'Loading':
        return handlers.onLoading()
      case 'Failure':
        return handlers.onFailure(requiredData.errorMessage)
      case 'Success':
        return handlers.onSuccess(requiredData.value)
      default:
        return assertNever(requiredData)
    }
  }

const requiredData = {
  fold,
  loading,
  success,
  failure,
}

export default requiredData
