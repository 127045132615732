import { useContext } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import './App.css'
import { AuthContext, AuthContextProvider } from './context/AuthContext'
import Header from './Header'
import { LoginPage } from './LoginPage'
import InvoicePaymentConfirmation from './payment/InvoicePaymentConfirmation'
import InvoicesPayment from './payment/InvoicesPayment'

function App() {
  return (
    <Router>
      <AuthContextProvider>
        <Switch>
          <Route exact path="/login">
            <LoginPage />
          </Route>
          <Route exact path="/public-page">
            <PublicPage />
          </Route>
          <Route path="/">
            <PrivateApp />
          </Route>
        </Switch>
      </AuthContextProvider>
    </Router>
  )
}

const PrivateApp = () => {
  const { authState } = useContext(AuthContext)
  const privateApp = (
    <>
      <Header />
      <div className="content">
        <Switch>
          <Route exact path="/">
            <InvoicesPayment />
          </Route>
          <Route exact path="/confirm-payment" component={InvoicePaymentConfirmation} />
        </Switch>
      </div>
    </>
  )
  return authState.type === 'SignedIn' ? privateApp : <Redirect to="/login" />
}

const PublicPage = () => {
  return (
    <>
      <Header />
      <div className="content">
        <Switch>This is a public app, e.g. landing page, which also allows user to sign in</Switch>
      </div>
    </>
  )
}

export default App
