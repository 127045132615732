/**
 * TODO: parse the following variants:
 * - generic error with user-readable message
 * - tpp messages (maybe parse json object on backend?)
 * - non-user-readable messages
 */
export const showErrorResponse = (jsonString: string): string => {
  let result = jsonString
  try {
    const parsedErrorObject = JSON.parse(jsonString)
    if (typeof parsedErrorObject.errorMessage === 'string') {
      return (result = parsedErrorObject.errorMessage)
    }
  } catch (err) {}
  return result
}
