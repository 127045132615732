import cx from 'classnames'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import styles from './InvoicesList.module.css'
import invoiceStatusLabelStyles from './InvoiceStatusLabel.module.css'
import { downloadFile } from './payment-backend'
import { CANCELLED, PAID, PAYMENT_PENDING, READY_FOR_PAYMENT, UNBOOKED } from './PaymentStatus'

type Props = {
  invoices: any[]
  onInvoiceSelected: () => void
  multiSelectEnabled: boolean
  onSelectionChange: (_: any[]) => void
}

function InvoicesList({ invoices, onInvoiceSelected, multiSelectEnabled, onSelectionChange }: Props) {
  const [selectedInvoices, setSelectedInvoices] = useState<any[]>([])
  const initialRender = useRef(true)

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
      return
    }

    if (onSelectionChange) {
      onSelectionChange(selectedInvoices)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInvoices])

  const handleInvoiceSelection = (invoice: any, isSelected: boolean) => {
    if (isSelected) {
      setSelectedInvoices([invoice])
    } else {
      setSelectedInvoices([])
    }
  }

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr>
            {multiSelectEnabled && (
              <th>
                <input
                  type="checkbox"
                  // checked={false
                  // onChange={null}
                />
              </th>
            )}
            <th className={styles.th}>Invoice #</th>
            <th className={styles.th}>Supplier</th>
            <th className={styles.th}>Invoice Date</th>
            <th className={styles.th}>Due Date</th>
            <th className={styles.th}>Status</th>
            <th className={[styles.th, styles.right].join(' ')}>Total</th>
            <th className={styles.th}>Files</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice) => (
            <InvoiceRow
              key={invoice.id}
              invoice={invoice}
              onClick={onInvoiceSelected}
              multiSelectEnabled={multiSelectEnabled}
              isSelected={selectedInvoices.includes(invoice)}
              onSelectionChange={handleInvoiceSelection}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

const isAllowedToBePaid = (invoiceStatus: string) =>
  process.env.REACT_APP_DEBUG_ALLOW_TO_PAY_FOR_ANY_INVOICE_STATUS === 'true'
    ? true
    : invoiceStatus === READY_FOR_PAYMENT

function InvoiceRow({ invoice, onClick, multiSelectEnabled, isSelected, onSelectionChange }: any) {
  const { authState, signOut } = useContext(AuthContext)
  const openFile = async (file: { fileId: string }) => {
    if (authState.type === 'SignedOut') {
      throw new Error('User is not authenticated')
    }
    const fileURL = await downloadFile(authState.token, signOut, file.fileId)
    window.open(fileURL)
  }

  return (
    <tr key={invoice.id} onClick={() => onClick && onClick(invoice)} className={styles.invoiceRow}>
      {multiSelectEnabled && (
        <td>
          <input
            type="checkbox"
            // We suppose user should be able to pay for unbooked and cancelled invoices
            disabled={!isAllowedToBePaid(invoice.status)}
            checked={isSelected}
            onChange={(e) => onSelectionChange(invoice, e.target.checked)}
          />
        </td>
      )}
      <td>{invoice.number}</td>
      <td>{invoice.supplier.name}</td>
      <td>{invoice.date}</td>
      <td>{invoice.dueDate}</td>
      <td>
        <InvoiceStatusLabel status={invoice.status} />
      </td>
      <td className={styles.right}>
        {invoice.total} {invoice.currency}
      </td>
      <td>
        {invoice.files.map((f: { fileId: string }, i: number) => (
          <a key={i} href="#" onClick={() => openFile(f)}>
            PDF
          </a>
        ))}
      </td>
    </tr>
  )
}

function InvoiceStatusLabel({ status }: { status: string }) {
  function getStatusLabelText(status: string) {
    switch (status) {
      case UNBOOKED:
        return 'unbooked'
      case READY_FOR_PAYMENT:
        return 'to be paid'
      case PAYMENT_PENDING:
        return 'pending'
      case PAID:
        return 'paid'
      case CANCELLED:
        return 'cancelled'
      default:
        return ''
    }
  }

  function getStatusClass(status: string) {
    switch (status) {
      case UNBOOKED:
        return invoiceStatusLabelStyles.unbooked
      case READY_FOR_PAYMENT:
        return invoiceStatusLabelStyles.readyForPayment
      case PAYMENT_PENDING:
        return invoiceStatusLabelStyles.paymentPending
      case PAID:
        return invoiceStatusLabelStyles.paid
      case CANCELLED:
        return invoiceStatusLabelStyles.cancelled
      default:
        return null
    }
  }

  return (
    <div className={cx(invoiceStatusLabelStyles.invoiceLabelStatus, getStatusClass(status))}>
      {getStatusLabelText(status)}
    </div>
  )
}

export default InvoicesList
