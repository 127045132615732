/**
 * This module is designed to be used with prefix:
 * import optionalData, { OptionalData } from ...
 */

import { assertNever } from './assertNever'

/**
 * This type represents state of remotely loaded data.
 * Most useful for manually-loaded data.
 */
export type OptionalData<A> =
  | { type: 'NotRequested' }
  | { type: 'Loading' }
  | { type: 'Failure'; errorMessage: string }
  | { type: 'Success'; value: A }

/**
 * Note: smart-constructors are helpful because they produce different objects every time.
 */

/**
 * Smart-constructor
 */
const notRequested = <A = never>(): OptionalData<A> => ({
  type: 'NotRequested',
})

/**
 * Smart-constructor
 */
const loading = <A = never>(): OptionalData<A> => ({
  type: 'Loading',
})

/**
 * Smart-constructor
 */
const failure = <A = never>(errorMessage: string): OptionalData<A> => ({
  type: 'Failure',
  errorMessage,
})

/**
 * Smart-constructor
 */
const success = <A = void>(value: A): OptionalData<A> => ({
  type: 'Success',
  value,
})

/**
 * "Extracts value" from OptionalData
 * by converting each possible variant to a value of some speific type
 */
const fold =
  <A, B>(handlers: {
    onNotRequested: () => B
    onLoading: () => B
    onFailure: (errorMessage: string) => B
    onSuccess: (value: A) => B
  }) =>
  (optionalData: OptionalData<A>): B => {
    switch (optionalData.type) {
      case 'NotRequested':
        return handlers.onNotRequested()
      case 'Loading':
        return handlers.onLoading()
      case 'Failure':
        return handlers.onFailure(optionalData.errorMessage)
      case 'Success':
        return handlers.onSuccess(optionalData.value)
      default:
        return assertNever(optionalData)
    }
  }

const optionalData = {
  fold,
  notRequested,
  loading,
  success,
  failure,
}

export default optionalData
