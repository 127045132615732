import LogRocket from 'logrocket'
import { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import { confirmPayment } from './payment-backend'

function InvoicePaymentConfirmation() {
  const location = useLocation()
  const { authState, signOut } = useContext(AuthContext)
  const urlParams = new URLSearchParams(location.search)
  const stateUrlParam = urlParams.get('state')
  const code = urlParams.get('code')
  console.log({ s: location.search, stateUrlParam, code })

  useEffect(() => {
    if (authState.type === 'SignedOut') {
      throw new Error('User is not authenticated')
    }
    if (stateUrlParam && code) {
      const [paymentId, authorizationId] = stateUrlParam.split(':')
      console.log({ s: location.search, paymentId, authorizationId, code })
      LogRocket.info('InvoicePaymentConfirmation', { paymentId, code, stateUrlParam })
      confirmPayment(authState.token, signOut, paymentId, code)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <h1>Thank you for the payment</h1>
    </div>
  )
}

export default InvoicePaymentConfirmation
