import { isSome } from 'fp-ts/Option'
import LogRocket from 'logrocket'
import { decodeToken } from './TokenInfo'
import { getUserInfo } from './UserInfo'

export const identifyLogrocketUser = async (token: string): Promise<void> => {
  const { userId } = decodeToken(token)
  const userInfo = await getUserInfo(token)
  const data: Record<string, string | boolean | number> = {
    name: userInfo.name,
    email: userInfo.email,
  }
  if (isSome(userInfo.companyName)) {
    data.companyName = userInfo.companyName.value
  }

  return LogRocket.identify(userId, data)
}
