import { isLeft } from 'fp-ts/Either'
import { exact, number, string, type, TypeOf } from 'io-ts'

import jwt_decode from 'jwt-decode'

const tokenInfoC = exact(
  type({
    userId: string,
    iat: number,
  }),
)

export type TokenInfo = TypeOf<typeof tokenInfoC>

/**
 * Trows an arror on decoding failure
 */
export const decodeToken = (token: string): TokenInfo => {
  const jwtDecoded = jwt_decode(token)
  const payloadDecoded = tokenInfoC.decode(jwtDecoded)
  if (isLeft(payloadDecoded)) {
    throw new Error('Got an unexpected token content.')
  }
  return payloadDecoded.right
}
