import { SyntheticEvent, useContext, useState } from 'react'
import { Redirect } from 'react-router'
import { AuthContext } from './context/AuthContext'
import styles from './LoginPage.module.css'
import { ReactComponent as Logo } from './logo.svg'
import optionalData, { OptionalData } from './utils/OptionalData'

export const LoginPage = () => {
  const { signIn, authState } = useContext(AuthContext)
  const [pageState, setPageState] = useState<OptionalData<void>>(optionalData.notRequested())
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()
    setPageState(optionalData.loading())
    signIn(email, password)
      .then(() => setPageState(optionalData.success(undefined)))
      .catch((err) => setPageState(optionalData.failure(err.message)))
  }

  const pageStateIndicator = optionalData.fold({
    onNotRequested: () => <></>,
    onLoading: () => <div>...</div>,
    onFailure: (errorMessage) => <div>Error: {errorMessage}</div>,
    onSuccess: () => <></>,
  })(pageState)

  const loginPage = (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={onSubmit}>
        <Logo className={styles.logo} />
        <label htmlFor="input-email">E-mail</label>
        <input
          id="input-email"
          type="text"
          value={email}
          disabled={pageState.type === 'Loading'}
          onChange={(e) => setEmail(e.target.value)}
        ></input>
        <label htmlFor="input-password">Password</label>
        <input
          id="input-password"
          type="password"
          value={password}
          disabled={pageState.type === 'Loading'}
          onChange={(e) => setPassword(e.target.value)}
        ></input>
        <button disabled={pageState.type === 'Loading'} type="submit">
          Sign In
        </button>
        {pageStateIndicator}
      </form>
    </div>
  )

  return authState.type === 'SignedIn' && pageState.type === 'NotRequested' ? <Redirect to="/" /> : loginPage
}
