import cx from 'classnames'
import LogRocket from 'logrocket'
import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-modal'
import QRCode from 'react-qr-code'
import { useLocation } from 'react-router-dom'
import Loader from 'react-spinners/PulseLoader'
import { AuthContext } from '../context/AuthContext'
import { showErrorResponse } from '../utils/ErrorResponse'
import notification, { Notification } from '../utils/Notification'
import { useInterval } from './helpers'
import InvoicesList from './InvoicesList'
import InvoicesListContainer from './InvoicesListContainer'
import styles from './InvoicesPayment.module.css'
import { NotificationPopup } from './NotificationPopup'
import { confirmPayment, getAuthorizationStatus, initiateInvoicePayment, requestQuote } from './payment-backend'

Modal.setAppElement('#root')

function InvoicesPayment() {
  const { authState, signOut } = useContext(AuthContext)
  const [selectedInvoices, setSelectedInvoices] = useState<any[]>([])
  const [showQRCodeModal, setShowQRCodeModal] = useState(false)

  const [paymentInitiationStarted, setPaymentInitiationStarted] = useState<boolean>(false)
  const [autoStartToken, setAutoStartToken] = useState(null)
  const [paymentId, setPaymentId] = useState(null)
  const [pendingPaymentId, setPendingPaymentId] = useState(null)
  const [paymentProduct, setPaymentProduct] = useState(null)
  const [authorizationId, setAuthorizationId] = useState(null)
  const [authStatus, setAuthStatus] = useState<string | null>(null)
  const [isWaitingForAuthorization, setIsWaitingForAuthorization] = useState(false)
  const [selectedInternationalInvoices, setSelectedInternationalInvoices] = useState<any[]>([])
  const [invoiceQuotes, setInvoiceQuotes] = useState({})
  const [isRequestingQuote, setRequestingQuote] = useState(false)
  const [allSelectedInternationalInvoicesAreQuoted, setAllSelectedInternationalInvoicesAreQuoted] = useState(false)

  const [notifications, setNotifications] = useState<[string, Notification][]>([])

  const appendNotification = (newNotification: Notification, existingId?: string) => {
    const notificationId = existingId || Math.random().toString(36).substring(2)
    setNotifications([[notificationId, newNotification], ...notifications])
  }
  const closeNotification = (notificationId: string) => {
    const notification = notifications.find(([id]) => id === notificationId)
    if (notification) {
      notification[1].onClose()
    }
    setNotifications(notifications.filter(([id]) => id !== notificationId))
  }

  const location = useLocation()

  useEffect(() => {
    if (authState.type === 'SignedOut') {
      throw new Error('User is not authenticated')
    }
    const urlParams = new URLSearchParams(location.search)
    const codeParam = urlParams.get('code')
    const state = urlParams.get('state')
    const isPaymentCallback = state && state.indexOf('action=payment') >= 0
    if (isPaymentCallback && codeParam && state) {
      const pendingPaymentId = state.split(',')[1].split('=')[1] // TODO: query string
      finalizeRedirectAuthPayment(pendingPaymentId, codeParam)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleInvoicesSelectionChange = (selectedInvoices: any[]) => {
    console.log(selectedInvoices)
    setSelectedInvoices(selectedInvoices)
  }

  useEffect(() => {
    setSelectedInternationalInvoices(selectedInvoices.filter(isInternationalInvoice))
  }, [selectedInvoices])

  useEffect(() => {
    const selectedInternationalInvoiceIds = selectedInternationalInvoices.map((i) => i.id)
    const quotedInvoiceIds = Object.keys(invoiceQuotes)

    const all = selectedInternationalInvoiceIds.every((id) => quotedInvoiceIds.includes(id))
    setAllSelectedInternationalInvoicesAreQuoted(all)
  }, [selectedInternationalInvoices, invoiceQuotes])

  async function getQuotesForSelectedInvoices() {
    if (authState.type === 'SignedOut') {
      throw new Error('User is not authenticated')
    }
    try {
      setRequestingQuote(true)
      for (const i of selectedInternationalInvoices) {
        const quote = await requestQuote(authState.token, i.id)
        setInvoiceQuotes({
          ...invoiceQuotes,
          [i.id]: quote,
        })
      }
    } finally {
      setRequestingQuote(false)
    }
  }

  function getAmountInSEK(invoiceId: string) {
    // @ts-ignore
    return invoiceQuotes[invoiceId] && invoiceQuotes[invoiceId]['amount']
  }

  const paySelectedInvoices = async () => {
    if (authState.type === 'SignedOut') {
      throw new Error('User is not authenticated')
    }
    setAuthStatus(null)
    setPaymentInitiationStarted(true)
    const invoice = selectedInvoices[0]

    let args
    if (isInternationalInvoice(invoice)) {
      args = {
        type: 'InternationalInvoicePayment',
        invoiceId: invoice.id,
        // @ts-ignore
        quoteId: invoiceQuotes[invoice.id].quoteId,
      }
    } else {
      args = { type: 'DomesticInvoicePayment', invoiceId: invoice.id }
    }

    try {
      // @ts-ignore
      const { paymentId, authorizationId, autoStartToken, redirectUrl, paymentProduct, pendingPaymentId } =
        // @ts-ignore
        await initiateInvoicePayment(authState.token, signOut, args)
      if (redirectUrl) {
        window.location.assign(redirectUrl)
        return
      } else {
        setPendingPaymentId(pendingPaymentId)
        setShowQRCodeModal(true)
        setAutoStartToken(autoStartToken)
        setPaymentId(paymentId)
        setAuthorizationId(authorizationId)
        setPaymentProduct(paymentProduct)
        setIsWaitingForAuthorization(true)
      }
    } catch (err) {
      appendNotification(
        notification.error('Payment failed', showErrorResponse(err.message), () => {
          setPaymentInitiationStarted(false) // or location change?
        }),
      )
    }
  }

  function isInternationalInvoice(invoice: any) {
    return invoice.currency !== 'SEK'
  }

  const getAmountToPay = () => {
    if (selectedInvoices.length === 0) return ''

    if (selectedInternationalInvoices.length > 0) {
      return `${getAmountInSEK(selectedInternationalInvoices[0].id)} SEK`
    }

    return `${selectedInvoices[0].total} ${selectedInvoices[0].currency}`
  }

  const handleCancel = () => {
    setShowQRCodeModal(false)
    setIsWaitingForAuthorization(false)
    setPaymentInitiationStarted(false)
  }

  const handleClose = () => {
    setShowQRCodeModal(false)
    setPaymentInitiationStarted(false)
  }

  async function checkAuthorizationStatus() {
    if (authState.type === 'SignedOut') {
      throw new Error('User is not authenticated')
    }
    try {
      // @ts-ignore
      const status = await getAuthorizationStatus(authState.token, signOut, paymentId, authorizationId, paymentProduct)
      // @ts-ignore
      setAuthStatus(status)
      // @ts-ignore
      if (['finalised', 'failed'].includes(status)) {
        setIsWaitingForAuthorization(false)
        setPaymentInitiationStarted(false)
        // @ts-ignore
        if (isAuthorizationFinalized(status)) {
          // @ts-ignore
          await finalizeDecoupledAuthPayment(pendingPaymentId)
        }
      }
    } catch (err) {
      appendNotification(notification.error('Payment failed', showErrorResponse(err.message)))
      setIsWaitingForAuthorization(false)
      setShowQRCodeModal(false)
      setAutoStartToken(null)
      setPaymentInitiationStarted(false)
      console.error(err)
    }
  }

  function isAuthorizationFinalized(status: string) {
    return status === 'finalised'
  }

  async function finalizeRedirectAuthPayment(pendingPaymentId: string, code: string) {
    if (authState.type === 'SignedOut') {
      throw new Error('User is not authenticated')
    }
    // create pending payment for int invoice to return ppId
    LogRocket.info('finalizeRedirectAuthPayment', { paymentId, code })
    await confirmPayment(authState.token, signOut, pendingPaymentId, code)
    appendNotification(
      notification.info('Thank you!', 'The payment was successfully created', () => window.location.assign('/')),
    )
  }

  async function finalizeDecoupledAuthPayment() {
    if (authState.type === 'SignedOut') {
      throw new Error('User is not authenticated')
    }
    LogRocket.info('finalizeDecoupledAuthPayment', { pendingPaymentId })
    // @ts-ignore
    await confirmPayment(authState.token, signOut, pendingPaymentId)
    appendNotification(
      notification.info('Thank you!', 'The payment was successfully created', () => window.location.assign('/')),
    )
  }

  useInterval(
    async () => {
      if (!isWaitingForAuthorization) return

      await checkAuthorizationStatus()
    },
    // @ts-ignore
    isWaitingForAuthorization ? 5000 : null,
  )

  // @ts-ignore
  const renderInvoices = ({ isLoading, ...props }) => {
    const showQuoteBtn = selectedInternationalInvoices.length > 0 && !allSelectedInternationalInvoicesAreQuoted
    return (
      <>
        <h1 className={styles.title}>
          Invoices to pay
          <Loader loading={isLoading} size={10} />
        </h1>
        <h2 className={styles.subtitle}>The Invoices below has been certified and needs your payment signing</h2>
        {/* @ts-ignore */}
        <InvoicesList {...props} />
        {/* <AccountBalance /> */}
        {showQuoteBtn ? (
          <GetQuoteButton onClick={getQuotesForSelectedInvoices} loading={isRequestingQuote} />
        ) : (
          <PayButton
            onClick={paySelectedInvoices}
            amountToPay={getAmountToPay()}
            loading={paymentInitiationStarted && !showQRCodeModal}
            disabled={selectedInvoices.length === 0}
          />
        )}
      </>
    )
  }

  return (
    <>
      {/* @ts-ignore */}
      <InvoicesListContainer
        multiSelectEnabled={true}
        onSelectionChange={handleInvoicesSelectionChange}
        renderInvoices={renderInvoices}
      />
      <AuthPopup
        open={showQRCodeModal}
        onClose={handleClose}
        autoStartToken={autoStartToken}
        authStatus={authStatus}
        waitingForAuthorization={isWaitingForAuthorization}
        onCancel={handleCancel}
      />
      {notifications.length > 0 && (
        <NotificationPopup onClose={closeNotification} currentNotification={notifications[0]} />
      )}
    </>
  )
}

// @ts-ignore
function AuthPopup(props) {
  return (
    <Modal
      isOpen={props.open}
      onRequestClose={props.onClose}
      className={styles.authPopup}
      overlayClassName={styles.authPopupOverlay}
      // @ts-ignore
      show={true}
    >
      <h1 className={styles.authPopupTitle}>Scan with your mobile device to authorize payment</h1>
      <BankIdQRCode autoStartToken={props.autoStartToken} redirectUrl="bankid:///" />
      <div className={styles.authPopupStatus}>Status: {props.authStatus}</div>
      {props.waitingForAuthorization ? (
        <button onClick={props.onCancel} className={styles.authPopupButton}>
          Cancel
        </button>
      ) : (
        <button onClick={props.onClose} className={styles.authPopupButton}>
          Close
        </button>
      )}
    </Modal>
  )
}

// @ts-ignore
function BankIdQRCode({ autoStartToken, redirectUrl }) {
  return <QRCode value={`bankid:///?autostarttoken=${autoStartToken}&redirect=${redirectUrl}`} size={256} />
}

// @ts-ignore
function GetQuoteButton({ loading, onClick }) {
  return (
    <button className={cx(styles.payButton)} onClick={onClick}>
      Get quote
      <Loader loading={loading} size={5} color="white" />
    </button>
  )
}

// @ts-ignore
function PayButton({ amountToPay, disabled, loading, onClick }) {
  return (
    <button
      className={cx(styles.payButton, { [styles.payButtonDisabled]: disabled })}
      onClick={onClick}
      disabled={disabled}
    >
      Pay {amountToPay}
      <Loader loading={loading} size={5} color="white" />
    </button>
  )
}

export default InvoicesPayment
