import React from 'react'
import Modal from 'react-modal'
import notification, { Notification } from '../utils/Notification'
import styles from './InvoicesPayment.module.css'

export const NotificationPopup = ({
  onClose,
  currentNotification,
}: {
  onClose: (notificationId: string) => void
  currentNotification: [string, Notification]
}) => {
  const closeNotification = () => onClose(currentNotification[0])
  const modalContent = notification.fold({
    onInfo: (title, text) => (
      <>
        <h1 className={styles.authPopupTitle}>{title}</h1>
        <div className={styles.authPopupStatus}>{text}</div>
        <button onClick={closeNotification} className={styles.authPopupButton}>
          Ok
        </button>
      </>
    ),
    onError: (title, text) => (
      <>
        <h1 className={styles.authPopupTitle}>{title}</h1>
        <div className={styles.authPopupStatus}>{text}</div>
        <button onClick={closeNotification} className={styles.authPopupButton}>
          Close
        </button>
      </>
    ),
  })(currentNotification[1])
  return (
    <Modal
      isOpen
      // onRequestClose={closeNotification}
      className={styles.authPopup}
    >
      {modalContent}
    </Modal>
  )
}
