/**
 * This module is designed to be imported with prefix:
 * import notification, { Notification } from ...
 */

import { assertNever } from './assertNever'

/**
 * This type represents notification for user
 */
export type Notification =
  | { type: 'Info'; title: string; text: string; onClose: () => void }
  | { type: 'Error'; title: string; text: string; onClose: () => void }

/**
 * Note: smart-constructors are helpful because they produce different objects every time.
 */

/**
 * Smart-constructor
 */
const info = (title: string, text: string, onClose: () => void = () => {}): Notification => ({
  type: 'Info',
  title,
  text,
  onClose,
})

/**
 * Smart-constructor
 */
const error = (title: string, text: string, onClose: () => void = () => {}): Notification => ({
  type: 'Error',
  title,
  text,
  onClose,
})

/**
 * "Extracts value" from Notification
 * by converting each possible variant to a value of some specific type
 */
const fold =
  <B>(handlers: { onInfo: (title: string, message: string) => B; onError: (title: string, message: string) => B }) =>
  (notification: Notification): B => {
    switch (notification.type) {
      case 'Info':
        return handlers.onInfo(notification.title, notification.text)
      case 'Error':
        return handlers.onError(notification.title, notification.text)
      default:
        return assertNever(notification)
    }
  }

const notification = {
  fold,
  info,
  error,
}

export default notification
