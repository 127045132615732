import { getBlob, postJson, putJson } from '../utils/backendRequest'

type InvoicePaymentRequestArgs =
  | {
      type: 'InternationalInvoicePayment'
      invoiceId: string
      quoteId: string
    }
  | {
      type: 'DomesticInvoicePayment'
      invoiceId: string
    }

export const initiateInvoicePayment = (
  token: string,
  onAuthFailure: () => void,
  args: InvoicePaymentRequestArgs,
): Promise<unknown> => postJson(`/api/invoice-payments`, args, token, onAuthFailure)

type PaymentProduct = string

export const getAuthorizationStatus = (
  token: string,
  onAuthFailure: () => void,
  paymentId: string,
  authorizationId: string,
  paymentProduct: PaymentProduct,
): Promise<unknown> =>
  putJson(
    `/api/payments/${paymentProduct}/${paymentId}/authorizations/${authorizationId}/status`,
    {},
    token,
    onAuthFailure,
  )

export const confirmPayment = (
  token: string,
  onAuthFailure: () => void,
  pendingPaymentId: string,
  codeParam?: unknown,
): Promise<unknown> =>
  postJson(`/api/pending-payments/${pendingPaymentId}/completion`, { code: codeParam }, token, onAuthFailure)

type QuoteResponse = {
  amount: number
  qouteId: string // our own quote Id
}

export const requestQuote = (token: string, invoiceId: string): Promise<QuoteResponse> =>
  postJson(`/api/quotes`, { invoiceId }, token)

export const downloadFile = async (token: string, onAuthFailure: () => void, fileId: string): Promise<string> => {
  const blob = await getBlob(`/api/supplier-invoices/files/${fileId}`, {}, token, onAuthFailure)
  return URL.createObjectURL(blob)
}
